import { useCartState, useMarket } from '@app/_context';
import {
    getCartAddress,
    getCheckout,
    updateCheckoutMethod,
} from '@server/requests/checkout';
import { getCheckoutPayments } from '@server/requests/checkout/getCheckoutPayments';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo } from 'react';

import { isAdyenCheckoutEnabled } from '@/config/checkout';
import { BRAND_CODE } from '@/config/common';
import { getCartCookie } from '@/utils/cart/getCartCookie';
import getShippingCost from '@/utils/cart/getShippingCost';
import { gTagBeginCheckout } from '@/utils/googleTags';

import { CheckoutContext } from './Checkout.context';
import { useCheckoutStore } from './Checkout.store';
import { useGiftCard } from './giftcard/GiftCard.hooks';

/**
 * Get the checkout context
 * @returns CheckoutContext
 */
export function useCheckoutContext() {
    const context = useContext(CheckoutContext);

    if (!context) {
        throw new Error(
            'CheckoutContext must be used within a CheckoutProvider',
        );
    }

    return context;
}

/**
 * Fetch the checkout and cart details
 */
export function useCheckout() {
    const { serviceLocale: locale } = useMarket().state.market;
    const cartCookie = getCartCookie();

    return useQuery({
        queryKey: ['checkout', cartCookie?.id],
        queryFn: () =>
            getCheckout({
                locale,
                cartId: cartCookie?.value ?? '',
            }),
        retry: false,
        enabled: Boolean(cartCookie?.id),
    });
}

/**
 * Get the available payment methods for the current cart
 * @returns { data, refetch } - Payment methods and a function to refetch the payment methods
 */
export function useCheckoutPayments() {
    const cartCookie = getCartCookie();
    const { serviceLocale: locale } = useMarket().state.market;

    return useQuery({
        queryKey: ['checkoutPayments', cartCookie?.id],
        queryFn: () =>
            getCheckoutPayments({
                locale,
                cartId: cartCookie?.value ?? '',
            }),
        enabled: Boolean(cartCookie?.id),
    });
}

/**
 * Get the current cart summary details
 * @returns { cartTotal, giftCardAmount } - Cart total and gift card amount
 */
export function useCheckoutSummary() {
    const { cart } = useCartState();
    const { hasEstimatedShipping, shippingCost } = useShippingCost();
    const giftCard = useGiftCard();

    const giftCardAmount = useMemo(() => {
        if (!giftCard || !cart) {
            return 0;
        }

        if (giftCard?.amount > cart?.totalGrossAmount) {
            return cart?.totalGrossAmount;
        }

        return giftCard?.amount;
    }, [giftCard, cart]);

    const cartTotal = useMemo(() => {
        let totalGrossAmount = cart?.totalGrossAmount ?? 0;

        if (hasEstimatedShipping) {
            totalGrossAmount += shippingCost;
        }

        return totalGrossAmount - (giftCardAmount ?? 0);
    }, [
        cart?.totalGrossAmount,
        hasEstimatedShipping,
        shippingCost,
        giftCardAmount,
    ]);

    return {
        cartTotal,
        giftCardAmount,
    };
}

/**
 * Get the current cart shipping cost
 * @returns { hasEstimatedShipping, shippingCost } - Shipping cost and whether the shipping cost is estimated
 */
export function useShippingCost(shipping = true) {
    const { cart } = useCartState();
    const { market } = useMarket().state;

    const shippingCost = useMemo(() => {
        if (!shipping) return 0;
        return getShippingCost({
            cart,
            marketCode: market.code,
        });
    }, [cart, shipping, market.code]);

    return {
        hasEstimatedShipping: false,
        shippingCost,
    };
}

/**
 *  Get the current zip code value from the checkout store
 * @returns zipCode - string
 */

export function useCheckoutZipCode() {
    const { details } = useCheckoutContext();

    return useCheckoutStore((state) => {
        if (
            isAdyenCheckoutEnabled({
                marketConfig: details.marketConfig,
            }) === false
        ) {
            return state.address.zipCode;
        }

        if (state.hasSeparateDeliveryAddress) {
            return state.deliveryAddress?.deliveryZipCode;
        }

        return state.address.zipCode;
    });
}

/**
 * Refetch the checkout form when the cart total changes
 * Used for Adyen, Klarna and Walley payment methods
 * @param paymentMethodId - Payment method ID
 * @param callback - Function to call after the checkout form is refetched
 * @returns void
 */

export function useRefetchCheckoutForm<T>(
    { paymentMethodId }: { paymentMethodId: number },
    callback: () => Promise<T>,
) {
    const cartCookie = getCartCookie();
    const { cart } = useCartState();
    const { serviceLocale: locale } = useMarket().state.market;

    useEffect(() => {
        updateCheckoutMethod({
            locale,
            cartId: cartCookie?.value ?? '',
            methodId: paymentMethodId,
        })
            .then(() => callback())
            .catch((error) => {
                console.error(
                    `useRefetchCheckoutForm - Error refetching checkout form for payment method id ${paymentMethodId}`,
                    error,
                );
            });
    }, [
        callback,
        cart?.totalGrossAmount,
        locale,
        cartCookie?.value,
        paymentMethodId,
    ]);
}

/**
 * Mount the checkout page and send the begin_checkout event to GTM
 * @returns void
 */
export function useCheckoutMount() {
    const { cart } = useCartState();
    const { checkout } = useCheckoutContext();

    // Workaround to update and refetch the cart in the checkout context when the cart total changes
    useEffect(() => {
        if (checkout?.data?.cart.items.length !== cart?.items.length) {
            checkout?.refetch();
        }
    }, [checkout, cart?.items]);

    // Track the begin_checkout event
    useEffect(() => {
        gTagBeginCheckout(checkout?.data?.cart);
    }, [checkout?.data?.cart, checkout?.data?.cart?.items]);
}

/**
 * Get the cart address
 * This is used to get the address from the cart before the checkout address has been updated on the order
 * @todo Rename this hook to useAdyenCheckoutCartAddress, or probably a beter idea - rename market.adyenMarket to market.checkoutMarketCode
 * since useCartAddress is currently a general checkout hook.
 */
export function useCartAddress() {
    const cartCookie = getCartCookie();
    const { market } = useMarket().state;

    return useQuery({
        queryKey: ['cartAddress', cartCookie?.id],
        queryFn: () =>
            getCartAddress({
                cartId: cartCookie?.value ?? '',
                marketCode: market.adyenMarket,
                brandCode: BRAND_CODE,
            }),
        enabled: Boolean(cartCookie?.id),
    });
}
