import { Paragraph } from '@components/ui';
import { getStockStatusText } from '@ngg/storefront-utils';
import { useMemo } from 'react';
import type { Dictionary } from 'types/common';

import { getMarketConfigFromLocale } from '@/config/market-configurations';
import { cn } from '@/lib/utils';
import { isMadeToOrderItem } from '@/utils/product/isMadeToOrderItem';
import { isSoonBackInStock } from '@/utils/product/isSoonBackInStock';
import { shouldShowStockIcon } from '@/utils/product/shouldShowStockIcon';

import { StockStatusIcon } from '../StockStatusIcon';

export default function Stock({
    stockStatus,
    deliveryMin,
    deliveryMax,
    deliveryUnit,
    dictionary,
    className,
    locale,
    nextDeliveryDate,
}: {
    stockStatus?: string | null;
    deliveryMin?: string | null;
    deliveryMax?: string | null;
    deliveryUnit?: string | null;
    dictionary: Dictionary;
    className?: string;
    locale?: string;
    nextDeliveryDate?: Date;
}) {
    const { contentfulLocale: contentfulLanguage } =
        getMarketConfigFromLocale(locale);
    const { stockStatusText, soonBackInStock, showStockIcon, madeToOrder } =
        useMemo(() => {
            const stockStatusText = getStockStatusText({
                stockStatus: stockStatus ?? '',
                deliveryMin: deliveryMin ?? '',
                deliveryMax: deliveryMax ?? '',
                deliveryUnit: deliveryUnit ?? '',
                dictionary,
                locale: contentfulLanguage,
                nextDeliveryDate,
            });
            const soonBackInStock = isSoonBackInStock(
                stockStatus,
                nextDeliveryDate,
            );
            const showStockIcon = shouldShowStockIcon(
                stockStatus,
                nextDeliveryDate,
            );
            const madeToOrder = isMadeToOrderItem(
                stockStatus,
                nextDeliveryDate,
            );
            return {
                stockStatusText,
                soonBackInStock,
                showStockIcon,
                madeToOrder,
            };
        }, [
            stockStatus,
            deliveryMin,
            deliveryMax,
            deliveryUnit,
            dictionary,
            contentfulLanguage,
            nextDeliveryDate,
        ]);

    return (
        <Paragraph
            size="xxsmall"
            transform="uppercase"
            className={cn('flex items-center gap-x-1', className)}>
            {showStockIcon && (
                <StockStatusIcon isWarning={soonBackInStock || madeToOrder} />
            )}
            {stockStatusText || 'In stock'}
        </Paragraph>
    );
}
